.job-creation {
  .ant-modal-content {
    padding: 0;
    border-radius: 4px;
  }
  .ant-modal-header {
    margin: 0;
    padding: 40px 0 0 32px;
    border-radius: 4px;
  }
  .header {
    padding: 0;
    h4 {
      color: #212121;
      font-size: 28px;
      font-family: Gordita, sans-serif;
      line-height: 37px;
      letter-spacing: 0;
      margin: 0;
    }

    .title {
      font-size: 28px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      font-family: Gordita, sans-serif;
      color: #212121;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.15px;
      margin-top: 16px;
    }

    .anticon-check-circle {
      color: #66cc80;
    }
  }

  .ant-modal-body {
    padding: 30px 32px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .pagination {
      font-size: 14px;
      line-height: 21px;
      font-family: Gordita, sans-serif;
      color: #212121;
    }

    .ant-btn {
      font-size: 14px;
      font-family: 'Gordita';
    }

    .create-job-or-next-btn:focus {
      background: #2e6edf;
      border-color: #2e6edf;
    }

    .create-job-or-next-btn:hover {
      color: #fff;
      border-color: #40a9ff;
      background: #40a9ff;
    }
  }

  .last-page-footer {
    justify-content: flex-end;
  }

  .ant-modal-header {
    border: none;
  }

  .error-border {
    border: 1px solid red;
  }

  .error-label {
    color: red;
    margin-top: 3px;
    font-size: 12px;
    font-family: Gordita, sans-serif;
  }
}
.job-creation-modal-wrap {
  background-color: var(--body-background);

  &::before {
    content: url(../../../../assets/img/turing-logo-wordmark.svg);
    position: absolute;
    width: 100px;
    height: 14.38px;
    top: 40px;
    left: 48px;
  }
}
