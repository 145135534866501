.job-post-button-container {
  .ant-btn-primary {
    min-height: 32px;
    margin-right: 30px;
    background-color: #2e6edf;
  }
}

.post-job-btn {
  background-color: #2e6edf;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 16px;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2e6edf;
  }

  &-text {
    font-weight: 500;
    letter-spacing: 0.25px;
  }

  &-icon {
    margin-right: 10px;
    font-weight: 500;
    font-size: 16px;
  }
}
.job-post-button-container {
  .post-job-btn {
    margin-right: 10px;
  }
}
