.job-description {
  .selection-container {
    .label {
      font-size: 16px;
      color: #212121;
      margin-bottom: 8px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    .sub-label {
      font-size: 12px;
      color: #212121;
      margin-bottom: 16px;
      font-family: Gordita, sans-serif;
    }
  }

  .preference {
    .ant-input-textarea-show-count::after {
      margin-top: 5px;
      font-size: 11px;
      font-family: Gordita, sans-serif;
    }
  }
}
