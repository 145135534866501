.job-creation-modal-v2 {
  --br-modal: 8px;

  .ant-modal-content {
    padding: 0;
    border-radius: var(--br-modal);
  }

  .ant-modal-header {
    margin: 0;
    padding: 32px 32px 0;
    border-top-left-radius: var(--br-modal);
    border-top-right-radius: var(--br-modal);

    .anticon-check-circle {
      color: #66cc80;
    }
  }

  .ant-modal-body {
    padding: 40px 32px;
    display: grid;
    row-gap: 32px;
    &:empty {
      padding: 16px;
    }
  }

  .ant-modal-footer {
    border-bottom-left-radius: var(--br-modal);
    border-bottom-right-radius: var(--br-modal);
  }

  .title {
    color: #212121;
    font-size: 28px;
    font-family: Gordita, sans-serif;
    line-height: 150%;
    letter-spacing: 0;
    margin: 0;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .pagination {
      font-size: 14px;
      line-height: 21px;
      font-family: Gordita, sans-serif;
      color: #212121;
    }

    .ant-btn {
      font-size: 14px;
      font-family: 'Gordita';
    }

    .create-job-or-next-btn:focus {
      background: #2e6edf;
      border-color: #2e6edf;
    }

    .create-job-or-next-btn:hover {
      color: #fff;
      border-color: #40a9ff;
      background: #40a9ff;
    }
  }

  .last-page-footer {
    justify-content: flex-end;
  }

  .ant-modal-header {
    border: none;
  }

  .error-border {
    border: 1px solid red;
  }

  .error-label {
    color: red;
    margin-top: 3px;
    font-size: 12px;
    font-family: Gordita, sans-serif;
  }

  .role-and-skills .selection-container,
  .location-and-start-date .selection-container,
  .developer-responsibility .selection-container,
  .experience-selector,
  .job-title {
    margin-bottom: 0;
  }

  .tags-container {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    .fixed-skill-tag {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 5px;
      gap: 10px;

      background: #ffffff;
      /* Grayscale/Dark Gray (AAAAAA) */

      border: 1px solid #aaaaaa;
      border-radius: 6px;

      font-family: 'Gordita';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #444444;
      margin-right: 0;
      margin-bottom: 0;
    }

    .fixed-skill-tag-selected {
      background-color: #dce6fa;
      color: #10316a;
      &::before {
        content: '\2713';
        display: inline-block;
        color: #10316a;
      }
    }
  }
}

.job-creation-modal-wrap {
  background-color: var(--body-background);

  &::before {
    content: url(../../../../assets/img/turing-logo-wordmark.svg);
    position: absolute;
    width: 100px;
    height: 14.38px;
    top: 40px;
    left: 48px;
  }
}
