.cowokrers-modal-container {
  h3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
  }

  .invite-coworkers-form {
    margin-top: 32px;
  }

  .input-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
    letter-spacing: 0.0015em;
    color: #212121;
  }

  .email-helper-text {
    font-size: 12px;
    line-height: 16.8px;
    font-weight: 400;
    color: #888888;
  }

  .email-helper-text.email-helper-text-error {
    color: red;
  }

  .coworkers-from-input-container-error {
    border: 1px solid red;
  }

  .coworkers-notes-textarea {
    font-size: 16px;
    border-radius: 4px;
  }

  .coworkers-jobs-container {
    .coworker-jobs-title {
      font-weight: 500;
      line-height: 21px;
      color: #212121;
    }

    ul {
      padding: 0px;
    }

    ul li {
      display: flex;
      gap: 12px;
      margin-top: 8px;
      list-style: none;
      font-weight: 400px;
      font-size: 14px;
      letter-spacing: 0.15px;
      color: #212121;
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-select-all-btn {
      color: #2e6edf;
      font-weight: 500;
    }
  }

  .coworkers-form-button-container {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;

    button {
      padding: 10px 16px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.25px;
      margin-left: 8px;
      outline: none;
      border: none;
      cursor: pointer;
    }

    button.coworkers-form-cancel-button {
      background-color: #ffffff;
      border: 1px solid #aaaaaa;
      border-radius: 6px;
      color: #212121;
    }

    button.coworkers-form-invite-button {
      background-color: #2e6edf;
      border-radius: 6px;
      color: #ffffff;
      font-weight: 500;
      border: 1px solid #2e6edf;
    }

    button.coworkers-form-invite-button.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
  }
}
