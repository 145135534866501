// breakpoints
$desktop-xl: 1920px;
$desktop: 1500px;
$desktop-sm: 1162px;
$tablet: 1098px;
$mobile: 768px;
$mobile-sm: 390px;
$mobile-xs: 360px;

// screens
$screen-sm: $mobile-sm;
$screen-md: $mobile;
$screen-lg: $tablet;
$screen-xl: $desktop;
$screen-xxl: $desktop-xl;

.container {
  height: 80vh;
  border-radius: 8px;
  max-width: 100%;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.containerLoading {
  position: absolute;
  height: 100%;
  border-radius: 8px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $mobile-sm) {
  .container {
    max-height: 70vh;
    overflow-y: scroll;
  }

  .containerLoading {
    max-height: 70vh;
  }
}
