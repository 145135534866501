@import './assets/scss/maps';
@import './assets/scss/mixins';

:root {
  @each $name, $resolution in $resolutions {
    --#{$name}: #{$resolution};
  }
  --brand: #2e6edf;
  --white: #ffffff;
  --bg-primary: var(--brand);
  --fc-text-white: var(--white);
  --fc-text-primary: var(--brand);
}

@font-face {
  font-family: 'GorditaBold';
  src: url('assets/fonts/hinted-GorditaBold.ttf') format('truetype');
}

@font-face {
  font-family: 'GorditaMedium';
  src: url('assets/fonts/hinted-GorditaMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'GorditaRegular';
  src: url('assets/fonts/hinted-GorditaRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gordita';
  src: url('assets/fonts/Gordita-regular.woff2') format('woff2'),
    url('assets/fonts/Gordita-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gordita';
  src: url('assets/fonts/Gordita-medium.woff2') format('woff2'),
    url('assets/fonts/Gordita-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Gordita', sans-serif;
  line-height: 1.5em;
  --default-margin: 24px;
  --body-background: #f8f8f8;
  --sidebar-width: 252px;
  --content-width: 1440px;
  --layout-inline-padding: 48px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.margin-bottom5 {
  margin-bottom: 5vh;
}

.full-width {
  width: 100%;
}

.ant-btn-primary {
  background: #2e6edf;
  border-color: #2e6edf;
}

.ant-btn {
  border-radius: 4px;
  font-family: 'Gordita', GorditaRegular, sans-serif;
  height: unset;
}

.ant-modal-content {
  box-shadow: 0px 0px 12px #0000000a;
  border-radius: 8px;
}

.ant-layout {
  background: var(--body-background);
}

.ant-layout-footer {
  background: var(--body-background);
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  border-color: transparent;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c7c7c7 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
h1.ant-typography,
.ant-typography h1,
h2.ant-typography,
.ant-typography h2,
h3.ant-typography,
.ant-typography h3,
h4.ant-typography,
.ant-typography h4,
h5.ant-typography,
.ant-typography h5,
h6.ant-typography,
.ant-typography h6 {
  font-weight: 500;
}

.ant-tooltip {
  @include for-mobile-only {
    display: none;
  }
}

.matching-developer-profile {
  .info-seniority-icon {
    @include for-mobile-only {
      display: none;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 16px;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.25px;
  border: 1px solid transparent;
  cursor: pointer;

  &-primary {
    background-color: var(--bg-primary);
    color: var(--fc-text-white);

    &:disabled,
    &.ant-tooltip-disabled-compatible-wrapper {
      background-color: #eee;
      color: #aaa;
      > button:disabled {
        border: none;
        padding: 0;
        background-color: transparent;
      }
    }
  }

  &-link {
    padding-inline: 0;
    background-color: transparent;
    color: var(--fc-text-primary);
  }
}

iframe#launcher {
  @include for-mobile-only {
    display: none;
  }
}

.btn-active-primary {
  padding: 10px 16px;
  gap: 6px;
  cursor: pointer;
  font-weight: 500;
  background: #2e6edf;
  color: white;
  font-family: Gordita, sans-serif;
  border-radius: 6px;
  border: none;
}

.btn-active-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 6px;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
}
