.primary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 6px;
  background: #2e6edf;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  line-height: 140%;
  font-weight: 500;
  letter-spacing: 0.25px;
  cursor: pointer;
  border: 1px solid #2e6edf;
}

.normal-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 6px;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}
