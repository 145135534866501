.native-call-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dddddd;
  padding: 12px 24px;
}

.native-call-container {
  position: relative;
  width: 100%;
  height: 100%;
}

:global(.native-call-booking-modal-container .ant-modal-body) {
  padding: 0;
}

@media screen and (max-width: 420px) {
  :global(.native-call-booking-modal-container .ant-modal-content) {
    border-radius: 0;
  }
}

:global(.native-call-booking-modal-container .ant-modal) {
  width: 100vw;
  margin: 0;
  max-width: 100vw;
}
:global(.native-call-booking-modal-container) {
  z-index: 1100;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
