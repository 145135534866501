.location-and-start-date {
  .selection-container {
    margin-bottom: 30px;

    .label {
      font-size: 16px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    p {
      font-size: 12px;
      font-family: Gordita, sans-serif;
    }

    .fixed-skill-tag {
      border: 1px solid #cccccc;
      border-radius: 4px;
      margin-right: 5px;
      background-color: white;
      font-size: 12px;
      padding: 2px 5px;
      color: #444444;
      margin-bottom: 5px;
      max-width: 150px;
      display: inline-flex;
      align-items: center;
    }

    .fixed-skill-tag-selected {
      background-color: #dce6fa;
      color: #10316a;
      &::before {
        content: '\2713';
        display: inline-block;
        color: #10316a;
        padding: 0 6px 0 0;
      }
    }

    .ant-switch {
      margin-left: 10px;
    }

    .ant-switch-checked {
      background-color: #2e6edf;
    }
  }
}
