.required-skills {
  .selection-container {
    .label {
      font-size: 16px;
      color: #212121;
      margin-bottom: 8px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    .sub-label {
      font-size: 12px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
    }
  }
}
