@import './maps';

@mixin for-mobile-only {
  @media screen and (max-width: map-get($resolutions, 'mobile')) {
    @content;
  }
}

@mixin for-tablet-only {
  @media screen and (max-width: map-get($resolutions, 'tablet')) {
    @content;
  }
}

@mixin for-greater-than-mobile {
  @media screen and (min-width: calc(map-get($resolutions, 'mobile') + 1px)) {
    @content;
  }
}

@mixin for-between-mobile-tablet-only {
  @media screen and (min-width: calc(map-get($resolutions, 'mobile') + 1px)) and (max-width: map-get($resolutions, 'tablet')) {
    @content;
  }
}
