.location-and-hours-overlap {
  .selection-container {
    .label {
      font-size: 16px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .sub-label {
      font-size: 12px;
      font-family: Gordita, sans-serif;
      margin-bottom: 8px;
    }

    .location-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .ant-radio-group {
      display: inline-flex;
      gap: 8px;
    }

    .ant-radio-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
      gap: 10px;
      font-family: 'Gordita';
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      background: #ffffff;
      /* Grayscale/Dark Gray (AAAAAA) */

      border: 1px solid #aaaaaa;
      border-radius: 6px;

      &:not(:first-child)::before {
        display: none;
      }

      &-checked {
        background-color: #dce6fa;
        color: #10316a;
        &::before {
          content: '\2713';
          display: inline-block;
          color: #10316a;
          background-color: transparent;
        }
      }
    }
  }
}
