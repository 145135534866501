.developer-responsibility {
  .selection-container {
    &:nth-child(2) {
      margin-bottom: 40px;
    }
    &:nth-child(3) {
      margin-bottom: 10px;
    }

    margin-bottom: 30px;

    .label {
      font-size: 15px;
      line-height: 21px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    .ant-radio-wrapper {
      .ant-radio {
        margin-right: 7px;
      }
      :nth-child(2) {
        font-size: 14px;
        line-height: 21px;
        color: #212121;
        font-family: Gordita, sans-serif;
      }
    }

    .ant-radio .ant-radio-inner {
      border-width: 2px;
    }

    .ant-radio-inner {
      border: solid 1px #747474;
      top: -1px;
    }

    .ant-space > .ant-space-item:not(:last-child) {
      margin-bottom: 12px !important;
    }

    .ant-switch {
      margin-left: 10px;
    }

    .ant-switch-checked {
      background-color: #444444;
    }

    .ant-checkbox-group {
      display: flex;
      flex-flow: column;
      label {
        margin-bottom: 15px;
        :nth-child(2) {
          font-size: 14px;
          line-height: 21px;
          color: #212121;
          font-family: Gordita, sans-serif;
        }
      }
    }

    .ant-checkbox .ant-checkbox-inner {
      border-width: 2px;
      border-color: #888888;
      margin-right: 7px;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #444444;
        border-color: #444444;
      }
    }

    .ant-checkbox-inner::after {
      left: 16%;
    }
  }
}
