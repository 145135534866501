.job-role {
  .selection-container {
    .label {
      font-size: 16px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    .other-role {
      margin-top: 15px;
      :nth-child(1) {
        font-size: 12px;
        line-height: 22px;
        font-family: Gordita, sans-serif;
        color: #212121;
      }
      :nth-child(2) {
        font-size: 13px;
        line-height: 22px;
        font-family: Gordita, sans-serif;
        color: #212121;
      }
    }

    .sub-label {
      font-size: 12px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
    }
  }
}
