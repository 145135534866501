.experience-selector {
  margin-bottom: 40px;

  .label {
    font-size: 16px;
    color: #212121;
    margin-bottom: 15px;
    font-family: Gordita, sans-serif;
    font-weight: 500;
  }

  .ant-dropdown-trigger {
    width: 200px;
    height: 40px;

    span {
      float: left;
      padding-top: 5px;
    }

    .anticon {
      float: right;
      padding-top: 5px;
    }
  }
}
