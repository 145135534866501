.job-positions {
  .label {
    font-size: 16px;
    color: #212121;
    font-family: Gordita, sans-serif;
    font-weight: 500;
  }

  .selection-container-inline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .ant-dropdown-trigger {
      margin-left: 16px;
      height: 39px;
    }
  }
}
