.page-slider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: auto;
}

section.ant-layout {
  width: 100%;
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
}

.ant-layout-has-sider .page-layout {
  margin-left: var(--sidebar-width);
}

.sidebar-content {
  background-color: white;
  padding: 5%;
}

.ant-layout-sider {
  background-color: white;
  box-shadow: 0px 2px 4px #0000000a;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-sider-trigger {
  background-color: #f8f8f8;
}

.ant-layout-sider-trigger svg {
  color: darkgray;
}

.page-layout-content {
  margin-top: 12px;
}

.content {
  display: block;
  margin-top: 64px;
  padding: 25px var(--layout-inline-padding);
  background: var(--body-background);
}

.sidebar-content h2 {
  margin-bottom: var(--default-margin);
}

.sidebar-content h3 {
  font-family: Gordita, sans-serif;
  font-size: 20px;
  margin-top: var(--default-margin);
}

.developers-container {
  display: grid;
  grid-template-columns: repeat(4, 350px);
  grid-gap: var(--default-margin);
  margin-bottom: 10px;
  justify-content: center;
}

.ant-layout-has-sider .developers-container {
  justify-content: initial;
}

@media screen and (min-width: 2400px) {
  .ant-layout-sider {
    min-width: var(--sidebar-width) !important;
  }
  .ant-layout-has-sider .page-layout {
    margin-left: var(--sidebar-width);
  }
}

@media only screen and (max-width: 600px) {
  .developers-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 650px) {
  .developers-container {
    grid-template-columns: repeat(1, 350px);
  }
}

@media only screen and (min-width: 1024px) {
  .developers-container {
    grid-template-columns: repeat(2, 350px);
  }
}

@media only screen and (min-width: 1398px) {
  .developers-container {
    grid-template-columns: repeat(3, minmax(1fr, 350px));
  }
}

.content.content--old {
  padding: 1% 1% 1% 2%;
}

@media screen and (max-width: 767px) {
  .content {
    --layout-inline-padding: 24px;
  }
}
