.customer-budget {
  .selection-container {
    .label {
      font-size: 16px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    input {
      width: 200px;
      height: 40px;
      font-size: 14px;
    }

    .helper-text {
      color: #888888;
      padding-top: 5px;
      font-size: 12px;
      font-family: Gordita, sans-serif;
      margin-bottom: 0;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #444444;
        border-color: #444444;
      }
    }
  }
}
