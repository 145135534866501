.fixed-skill-tag {
  border-radius: 12px;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 2px 8px;
}
.fixed-skill-tag-selected {
  background-color: #666666;
  color: white;
}
.fixed-skill-tag-unselected {
  background-color: #0000000a;
  color: #444444;
}

.fixed-skill-tag-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #8c8c8c;
}

.fixed-skill-tag-disabled:hover {
  color: #8c8c8c;
}
