.other-option {
  margin-top: 15px;
  :nth-child(1) {
    font-size: 12px;
    line-height: 22px;
    font-family: Gordita, sans-serif;
    color: #212121;
  }
  .ant-select-multiple .ant-select-selection-item {
    color: #444444;
    font-size: 12px;
    line-height: 22px;
    font-family: Gordita, sans-serif;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-right: 5px;
    background-color: white;
    padding: 2px 5px;
    margin-bottom: 5px;
    max-width: 160px;
    display: inline-flex;
    align-items: center;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    content: url('../../../../assets/img/round-cancel-24px.svg');
    width: 18px;
    height: 18px;
    padding: 0;
    margin-top: 0.5px;
  }
}
