.additional-preference {
  .selection-container {
    .label {
      font-size: 14px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    .sub-label {
      font-size: 12px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
    }

    .fixed-skill-tag {
      border: 1px solid #cccccc;
      border-radius: 4px;
      margin-right: 5px;
      background-color: white;
      font-size: 12px;
      padding: 2px 5px;
      color: #444444;
      margin-bottom: 5px;
      max-width: 160px;
      display: inline-flex;
      align-items: center;
    }

    .fixed-skill-tag-selected {
      background-color: #dce6fa;
      color: #10316a;
      &::before {
        content: '\2713';
        display: inline-block;
        color: #10316a;
        padding: 0 6px 0 0;
      }
    }
  }

  .selection-container-inline {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: baseline;

    .ant-dropdown-trigger {
      margin-left: 16px;
      height: 39px;
    }
  }

  .preference {
    margin-top: 30px;
    margin-bottom: 30px;

    .ant-input-textarea-show-count::after {
      margin-top: 5px;
      font-size: 11px;
      font-family: Gordita, sans-serif;
    }
  }
}
