.job-title {
  margin-bottom: 40px;

  .label {
    font-size: 16px;
    color: #212121;
    margin-bottom: 15px;
    font-family: Gordita, sans-serif;
    font-weight: 500;
  }

  input {
    height: 40px;
    font-size: 14px;
    font-family: Gordita, sans-serif;
  }
}
