.role-and-skills {
  .selection-container {
    &:nth-child(1) {
      margin-bottom: 30px;
    }
    margin-bottom: 15px;

    .label {
      font-size: 16px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
      font-weight: 500;
    }

    .other-role {
      margin-top: 15px;
      :nth-child(1) {
        font-size: 12px;
        line-height: 22px;
        font-family: Gordita, sans-serif;
        color: #212121;
      }
      :nth-child(2) {
        font-size: 13px;
        line-height: 22px;
        font-family: Gordita, sans-serif;
        color: #212121;
      }
    }

    .sub-label {
      font-size: 12px;
      color: #212121;
      margin-bottom: 15px;
      font-family: Gordita, sans-serif;
    }

    .fixed-skill-tag {
      border: 1px solid #cccccc;
      border-radius: 4px;
      margin-right: 5px;
      background-color: white;
      font-size: 12px;
      padding: 2px 5px;
      color: #444444;
      margin-bottom: 5px;
      max-width: 160px;
      display: inline-flex;
      align-items: center;
    }

    .fixed-skill-tag-selected {
      background-color: #dce6fa;
      color: #10316a;
      &::before {
        content: '\2713';
        display: inline-block;
        color: #10316a;
        padding: 0 6px 0 0;
      }
    }
  }
}
